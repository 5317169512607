<template>
  <div class="nav-top">
    <div class="nav-info">
      <div class="logo-nav">
        <div class="logo" @click="$router.push({path:'/'})">
          <img src="/static/images/logo.png" alt="">
        </div>
        <div class="location" @click="select_location = true">
          <span class="current-location">{{ city_list[city_index]?.name || '加载中' }}</span>
          <span class="more"></span>
        </div>
        <div class="nav">
          <router-link to="/" class="item" :class="{active:index === 0}">
            <span>首页</span>
          </router-link>
          <router-link to="/look" class="item" :class="{active:index === 1}">
            <span>看点</span>
          </router-link>
          <router-link to="/hotspot" class="item" :class="{active:index === 2}">
            <span>楼市热点</span>
          </router-link>
          <router-link to="/report" class="item" :class="{active:index === 3}">
            <span>快速报备</span>
          </router-link>
          <router-link to="/valuable" class="item" :class="{active:index === 4}">
            <span>论楼宝典</span>
          </router-link>
        </div>
      </div>
      <div class="login-info">
        <div class="login">
          <img :src="member?member.avatar_url:'/static/images/dltx_icon.png'" alt="">
        </div>
        <div class="text" v-if="!member">
          <span @click="login">登录</span>
          <span>/</span>
          <span @click="register">注册</span>
        </div>
        <div class="text" v-else @click="onMy">
          <span>{{ member.nick_name }}</span>
        </div>
      </div>
    </div>
    <div class="account-wrapper" v-if="account_type !== null">
      <div class="background" @click="account_type=null"></div>
      <div class="content-wrapper">
        <el-image class="top-image" src="/static/images/login-top.png" fit="contain"></el-image>
        <div class="login-wrapper" v-if="account_type === ACCOUNT_TYPE_LOGIN">
          <el-input class="phone" v-model="form.phone" maxlength="11" placeholder="输入手机号"/>
          <el-input class="passwd" v-model="form.passwd" type="password" placeholder="输入密码"/>
        </div>
        <div class="register-wrapper" v-if="account_type === ACCOUNT_TYPE_REGISTER">
          <el-input class="phone" v-model="form.phone" maxlength="11" placeholder="输入手机号"/>
          <el-input class="passwd" v-model="form.passwd" type="password" placeholder="输入密码"/>
          <el-input class="sms_code" v-model="form.sms_code" maxlength="6" placeholder="输入验证码">
            <template #suffix>
              <div class="get_sms_code" :class="{send:sms_send}" @click="handleClickSendSms">{{ sendSmsText }}</div>
            </template>
          </el-input>
          <div class="agree-wrapper">
            <el-checkbox v-model="form.agree" label="报备即默认同意" size="large"/>
            <span class="protocol" @click="handleProtocolClick">《论楼用户协议》</span>
          </div>
        </div>
        <div class="forgot_passwd-wrapper" v-if="account_type === ACCOUNT_TYPE_FORGOT_PASSWD">
          <div class="input-wrapper">
            <el-input class="phone" v-model="form.phone" maxlength="11" placeholder="输入手机号"/>
            <el-input class="sms_code" v-model="form.sms_code" maxlength="6" placeholder="输入验证码">
              <template #suffix>
                <div class="get_sms_code" :class="{send:sms_send}" @click="handleClickSendSms">{{ sendSmsText }}</div>
              </template>
            </el-input>
            <el-input class="passwd" v-model="form.passwd" type="password" placeholder="输入密码"/>
            <el-input class="passwd" v-model="form.confirm_passwd" type="password" placeholder="确认新密码"/>
          </div>
        </div>
        <el-button class="submit" :class="{loading:submitting}" @click="handleSubmitAccount">
          {{ accountButtonText }}
        </el-button>
        <div class="footer-wrapper">
          <template v-if="account_type === ACCOUNT_TYPE_LOGIN">
            <span @click="account_type=ACCOUNT_TYPE_REGISTER; ">注册</span>
            <span @click="account_type=ACCOUNT_TYPE_FORGOT_PASSWD">忘记密码</span>
          </template>
          <template v-if="account_type === ACCOUNT_TYPE_REGISTER">
            <span @click="account_type=ACCOUNT_TYPE_LOGIN">登录</span>
            <span @click="account_type=ACCOUNT_TYPE_FORGOT_PASSWD">忘记密码</span>
          </template>
          <template v-if="account_type === ACCOUNT_TYPE_FORGOT_PASSWD">
            <span @click="account_type=ACCOUNT_TYPE_LOGIN">登录</span>
            <span @click="account_type=ACCOUNT_TYPE_REGISTER">注册</span>
          </template>
        </div>
      </div>
    </div>
    <div class="city-select-wrapper" v-if="select_location">
      <div class="background" @click="select_location = false"></div>
      <div class="city-select">
        <div class="item" v-for="(item,index) in city_list" :key="item.id" @click="handleChangeCity(index)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <el-dialog
        v-model="privacy_agreement_show"
        title="论楼用户协议"
        width="70%"
    >
      <iframe class="privacy_agreement" src="/api/common/privacyAgreement"></iframe>
    </el-dialog>
  </div>
</template>

<script>
let jsonpAdapter = require('axios-jsonp')
let sms_countdown_interval = null
export default {
  name: 'NavTop',
  props: {
    index: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      ACCOUNT_TYPE_LOGIN: 'LOGIN',
      ACCOUNT_TYPE_REGISTER: 'REGISTER',
      ACCOUNT_TYPE_FORGOT_PASSWD: 'FORGOT_PASSWD',
      account_type: null,
      select_location: false,
      city_index: -1,
      city_list: [],
      sms_send: false,
      sms_countdown: 0,
      submitting: false,
      member: false,
      form: {
        phone: '',
        passwd: '',
        confirm_passwd: '',
        sms_code: '',
        agree: true,
      },
      privacy_agreement_show: false,
      privacy_agreement: '',
    }
  },
  mounted() {
    this.fetchData()
    this.initData()
  },
  methods: {
    initData() {
      let sms_countdown = localStorage.getItem('sms_countdown')

      if (sms_countdown !== null) {
        sms_countdown = Math.floor((parseInt(sms_countdown) - (new Date()).getTime()) / 1000)
        if (sms_countdown > 0) {
          this.sms_send = true
          this.sms_countdown = sms_countdown
          setInterval(this.handleSmsCountDown, 1000)
        } else {
          localStorage.removeItem('sms_countdown')
        }
      }

      let member = localStorage.getItem('MEMBER')

      if (member !== null) {
        this.member = JSON.parse(member)
      }
    },
    fetchData() {
      this.fetchCityList()
    },
    async fetchCityList() {
      let res = await this.http({
        methods: 'get',
        url: '/api/common/cityList',
      })
      let city_code = localStorage.getItem('CITY_CODE')
      // 之前没有
      if (city_code === null) {
        try {
          let tApiRes = await new Promise((resolve) => {
            this.http({
              url: 'https://apis.map.qq.com/ws/location/v1/ip',
              method: 'GET',
              adapter: jsonpAdapter,
              params: {
                key: 'UGBBZ-IL364-ILOUZ-XHPZK-Y22MS-XNBXY',
                output: 'jsonp',
                callback: 'addressCallback',
              }
            })

            window['addressCallback'] = function (aaa) {
              window['addressCallback'] = null
              resolve(aaa)
            }
          })
          if (tApiRes.status === 0 && tApiRes.result.ad_info.adcode !== undefined) {
            let code = tApiRes.result.ad_info.adcode.toString().substring(0, 4) + '00'

            for (let index in res.data) {
              if (res.data[index].code.toString() === code) {
                this.city_index = index
              }
            }
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        // 之前有的
        for (let index in res.data) {
          if (res.data[index].code.toString() === city_code) {
            this.city_index = index
          }
        }
      }

      if (this.city_index === -1) {
        this.city_index = 0
      }

      localStorage.setItem('CITY_CODE', res.data[this.city_index].code)
      this.$emit('cityLoaded')
      this.city_list = res.data
    },
    handleChangeCity(index) {
      this.city_index = index
      this.select_location = false
      localStorage.setItem('CITY_CODE', this.city_list[index].code)
      this.$emit('cityLoaded')
    },
    async handleSubmitAccount() {
      switch (this.account_type) {
        case this.ACCOUNT_TYPE_LOGIN:
          try {
            this.submitting = true
            let res = await this.http({
              url: '/api/account/login',
              method: 'POST',
              data: {
                phone_number: this.form.phone,
                passwd: this.form.passwd
              }
            })

            this.$message({
              message: '登录成功',
              type: 'success'
            })

            this.member = {
              member_id: res.data.id,
              open_id: res.data.openid,
              phone_number: res.data.phone_number,
              nick_name: res.data.nickName,
              avatar_url: res.data.avatarUrl,
              can_receptionist: res.data.can_receptionist,
              broker_company_name: res.data.broker_company_name,
              broker_company_id: res.data.broker_company_id,
              broker_name: res.data.broker_name,
              broker_phone: res.data.broker_phone,
            }

            localStorage.setItem('MEMBER', JSON.stringify(this.member))
            localStorage.setItem('MEMBER_ID', this.member.member_id)

            this.account_type = null
            this.$emit('afterLogin')

          } catch (e) {
            console.log(e)
          } finally {
            this.submitting = false
          }

          break
        case this.ACCOUNT_TYPE_FORGOT_PASSWD:
          if (this.form.phone === '') {
            this.$message({
              message: '请输入手机号',
              type: 'error'
            })
            return
          }

          if (this.form.sms_code === '') {
            this.$message({
              message: '请输入验证码',
              type: 'error'
            })
            return
          }

          if (this.form.passwd === '') {
            this.$message({
              message: '请输入密码',
              type: 'error'
            })
            return
          }

          if (this.form.confirm_passwd === '') {
            this.$message({
              message: '请输入确认新密码',
              type: 'error'
            })
            return
          }

          if (this.form.passwd !== this.form.confirm_passwd) {
            this.$message({
              message: '密码与确认新密码不符',
              type: 'error'
            })
            return
          }

          try {
            this.submitting = true

            await this.http({
              url: '/api/account/forgotPassword',
              method: 'POST',
              data: {
                phone_number: this.form.phone,
                sms_code: this.form.sms_code,
                passwd: this.form.passwd
              }
            })

            this.$message({
              message: '修改密码成功',
              type: 'success'
            })

            this.account_type = this.ACCOUNT_TYPE_LOGIN
          } catch (e) {
            console.log(e)
          } finally {
            this.submitting = false
          }


          break
        case this.ACCOUNT_TYPE_REGISTER:
          if (this.form.phone === '') {
            this.$message({
              message: '请输入手机号',
              type: 'error'
            })
            return
          }
          if (this.form.passwd === '') {
            this.$message({
              message: '请输入密码',
              type: 'error'
            })
            return
          }

          if (this.form.sms_code === '') {
            this.$message({
              message: '请输入验证码',
              type: 'error'
            })
            return
          }

          if (!this.form.agree) {
            this.$message({
              message: '请勾选同意协议',
              type: 'error'
            })
            return
          }

          try {
            this.submitting = true

            await this.http({
              url: '/api/account/phoneRegister',
              method: 'POST',
              data: {
                phone_number: this.form.phone,
                sms_code: this.form.sms_code,
                passwd: this.form.passwd
              }
            })

            this.$message({
              message: '注册成功',
              type: 'success'
            })

            this.account_type = this.ACCOUNT_TYPE_LOGIN
          } catch (e) {
            console.log(e)
          } finally {
            this.submitting = false
          }

          break
      }
    },
    async handleClickSendSms() {
      try {
        if (this.sms_send) {
          return
        }

        if (this.form.phone === '') {
          this.$message({
            message: '请输入手机号',
            type: 'error'
          })
          return
        }

        let res = await this.http({
          url: '/api/account/sendSmsCode',
          method: 'post',
          data: {
            phone_number: this.form.phone
          }
        })

        this.startSmsCountDown()

        this.$message({
          message: res.msg,
          type: 'success'
        })

        console.log(res)

      } catch (e) {
        console.log(e)
      }
    },
    startSmsCountDown() {
      this.sms_send = true
      this.sms_countdown = 60
      localStorage.setItem('sms_countdown', ((new Date()).getTime() + this.sms_countdown * 1000).toString())
      setInterval(this.handleSmsCountDown, 1000)

    },
    handleSmsCountDown() {
      if (this.sms_countdown <= 1) {
        this.sms_send = false
        clearInterval(sms_countdown_interval)
        sms_countdown_interval = null
        localStorage.removeItem('sms_countdown')
        return
      }
      this.sms_countdown -= 1
    },
    login() {
      this.account_type = this.ACCOUNT_TYPE_LOGIN
    },
    register() {
      this.account_type = this.ACCOUNT_TYPE_REGISTER
    },
    async handleProtocolClick() {
      this.privacy_agreement_show = true
    },
    /**
     * 个人中心点击
     */
    onMy() {
      this.$router.push({name: 'MessageList'})
    }
  },
  computed: {
    accountButtonText() {
      switch (this.account_type) {
        case this.ACCOUNT_TYPE_LOGIN:
          return '马上登录'
        case this.ACCOUNT_TYPE_REGISTER:
          return '立即注册'
        case this.ACCOUNT_TYPE_FORGOT_PASSWD:
          return '修改密码'
        default:
          return ''
      }
    },
    sendSmsText() {
      if (!this.sms_send) {
        return '发送验证码'
      }
      return `已发送(${this.sms_countdown})`
    }
  },
  watch: {
    account_type: function () {
      this.form.phone = ''
      this.form.passwd = ''
      this.form.agree = false
      this.form.confirm_passwd = ''
      this.form.sms_code = ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "NavTop.scss";
</style>
