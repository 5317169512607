<template>
  <div class="footer-info">
    <div class="footer">
      <div class="nav-info">
        <div class="logo">
          <img src="/static/images/logo1.png" alt="论楼">
        </div>
        <div class="nav-item">
          <div class="nav-title">
            <span>网站导航</span>
            <span class="line"></span>
          </div>
          <div class="item">
            <div class="nav">
              <router-link to="/">首页</router-link>
            </div>
            <div class="nav">
              <router-link to="/hotspot">楼市热点</router-link>
            </div>
            <div class="nav">
              <router-link to="/report">快速报备</router-link>
            </div>
          </div>
          <div class="item">
            <div class="nav">
              <router-link to="/look">看点</router-link>
            </div>
            <div class="nav">
              <router-link to="/valuable">论楼宝典</router-link>
            </div>
            <div class="nav" v-show="member_id">
              <router-link to="/">个人中心</router-link>
            </div>
          </div>

          <div class="item hide">
            &#12288;
          </div>
        </div>
        <div class="nav-item">
          <div class="nav-title">
            <span>关于我们</span>
            <span class="line"></span>
          </div>
          <div class="item">
            <div class="nav">
              <a href="javascript:;" @click="pc_index_company_name_show = true">公司名称</a>
            </div>
          </div>
          <div class="item">
            <div class="nav">
              <a href="javascript:;" @click="pc_index_business_license_show = true">营业执照</a>
            </div>
          </div>
          <div class="item">
            <div class="nav">
              <a href="javascript:;" @click="pc_index_work_permit_show = true">工作许可证</a>
            </div>
          </div>
        </div>
        <div class="nav-item">
          <div class="nav-title">
            <span>联系我们</span>
            <span class="line"></span>
          </div>
          <div class="item">
            <div class="nav">
              <img src="/static/images/dhx_ico.png" style="width: 20px;height: 16px;">
              <a href="javascript:;">电话：{{pc_index_contact}}</a>
            </div>
          </div>
          <div class="item">
            <div class="nav">
              <img src="/static/images/dwx_ico.png" style="width: 18px;height: 20px;">
              <a href="javascript:;">地址：{{pc_index_address}}</a>
            </div>
          </div>
          <div class="item">
            <div class="nav">
              <img src="/static/images/yx_ico.png" style="width: 20px;height: 15px">
              <a href="javascript:;">邮箱：{{pc_index_email}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright" @click="handleCopyrightClick">
      <div class="copyright-info">
        <div class="info">
          <span>Copyright © {{site_copyright}} 版权所有，并保留所有权利 | {{site_beian}}</span>
        </div>
      </div>
    </div>

    <el-dialog
        v-model="pc_index_company_name_show"
        title="公司名称"
        width="30%"
    >
      <p style="text-align: center; font-size: 22px; color: #000000;font-weight: bold;">{{ pc_index_company_name }}</p>
    </el-dialog>

    <el-dialog
        v-model="pc_index_business_license_show"
        title="营业执照"
        width="50%"
    >
      <img style="width: 100%" :src="pc_index_business_license">
    </el-dialog>

    <el-dialog
        v-model="pc_index_work_permit_show"
        title="工作许可证"
        width="50%"
    >
      <img style="width: 100%" :src="pc_index_work_permit">
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'FooterBar',
  data() {
    return {
      member_id: null,
      pc_index_company_name_show: false,
      pc_index_business_license_show: false,
      pc_index_work_permit_show: false,
      pc_index_company_name: '',
      pc_index_business_license: '',
      pc_index_work_permit: '',
      pc_index_contact: '',
      pc_index_address: '',
      pc_index_email: '',
      site_beian: '',
      site_copyright: '',
    }
  },
  mounted() {
    this.member_id = localStorage.getItem('MEMBER_ID')
    this.pc_index_company_name = localStorage.getItem('pc_index_company_name')
    this.pc_index_business_license = localStorage.getItem('pc_index_business_license')
    this.pc_index_work_permit = localStorage.getItem('pc_index_work_permit')
    this.pc_index_contact = localStorage.getItem('pc_index_contact')
    this.pc_index_address = localStorage.getItem('pc_index_address')
    this.pc_index_email = localStorage.getItem('pc_index_email')
    this.site_beian = localStorage.getItem('site_beian')
    this.site_copyright = localStorage.getItem('site_copyright')
  },
  methods:{
    handleCopyrightClick(){
      window.open('https://beian.miit.gov.cn/','_blank')
    }
  }
}
</script>

<style scoped lang="scss">
@import "FooterBar.scss";
</style>
